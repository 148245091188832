<template>
  <div style="height: 100%">
    <dataset-groups-list />
  </div>
</template>

<script>
export default {
  name: "DatasetGroups",
  components: {
    DatasetGroupsList: () => import("@/views/dataset-groups/DatasetGroupsList"),
  },
};
</script>

<style scoped></style>
